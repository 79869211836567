
  .resources-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 0 20px;
  }

  .select-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
      .select-left {
          .text {
              font-size: 14px;
              color: #333333;
              margin-right: 10px;
          }
          .resources-cascader {
              width: 420px;
              ::v-deep .el-input__inner {
                  border-radius: 0;
              }
          }
      }
      .select-btn {
          i {
              font-size: 12px;
              margin-right: 5px;
          }
      }
  }
  
  .type-wrapper {
      display: flex;
      align-items: center;
      margin-left: 20px;
      .type-name {
          width: 100px;
          line-height: 30px;
          color: #333;
      }
      .type-list {
          .type-item {
              display: inline-block;
              padding: 4px 10px;
              border-radius: 2px;
              font-size: 14px;
              border: 1px solid #D2D2D2;
              background-color: #fff;
              cursor: pointer;
              color: #666;
              transition: all .3s ease-in;
              margin-right: 10px;
              &.current {
                  color: #fff;
                  background: #2DC079;
                  border: 1px solid #2DC079;
              }
              &:hover {
                  background: #1AB163;
                  border: 1px solid #1AB163;
                  color: #fff;
              }
          }
      }
  }
  .select-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .select-left {
          .text {
              font-size: 14px;
              color: #333333;
              margin-right: 10px;
          }
          .resources-cascader {
              width: 420px;
              ::v-deep .el-input__inner {
                  border-radius: 0;
              }
          }
      }
      .select-btn {
          i {
              font-size: 12px;
              margin-right: 5px;
          }
      }
  }
    .logo-uploader {
  ::v-deep.el-upload {
    width: 80px;
    height: 80px;
    background: #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo-uploader-icon {
      font-size: 32px;
      color: #a0a0a0;
    }
  }
}
.column-logo-box {
  .column-logo {
    width: 48px;
    height: 48px;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    box-sizing: content-box;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
.logo-src-box {
  display: flex;
  width: 80px;
  height: 80px;
  box-sizing: border-box;
  border: 1px solid #EEEEEE;
  position: relative;
  img {
    max-width: 100%;
    max-height: 100%;
  }

  i {
    line-height: 1;
    font-size: 14px;
    color: #dcdcdc;
    position: absolute;
    right: -20px;
    top: 0;
    cursor: pointer;
  }
}
  .school-prompt {
  color: #999;
  font-size: 12px;
  width: 200px;
  height: 200px;
}
.dialog-footer{
      margin-top: 20px;
}
.block{
    display: inline-block;
}
